export default [
  {
    path: "/",
    name: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/agent/dashboard.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
  },
  {
    path: "/register/agent",
    name: "register",
    component: () => import("../views/pages/account/register"),
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
  },
  {
    path: "/reset",
    name: "Reset-password",
    component: () => import("../views/pages/account/reset-password"),
  },
  {
    path: "/agent/customers",
    name: "customers",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/customers/customers.vue"),
  },
  {
    path: "/agent/groups",
    name: "groups",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/groups/groups.vue"),
  },
  {
    path: "/agent/groups/member/:id",
    name: "groups-member",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/groups/group-members.vue"),
  },
  {
    path: "/agent/transactions",
    name: "transactions",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/agent/transactions.vue"),
  },
  {
    path: "/agent/verify-payment/:id",
    name: "verify-payment",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/customers/verify-payment.vue"),
  },
  {
    path: "/agent/verify-group-loan/:groupID/:id",
    name: "verify-payment-group",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/groups/verify-group-payment.vue"),
  },
  {
    path: "/agent/pay/loan/:id/:loadID",
    name: "make-payment",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/customers/pay.vue"),
  },
  {
    path: "/agent/savings-pay/loan/:id/:savingsID",
    name: "make-savings-payment",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/customers/savings-pay.vue"),
  },
  {
    path: "/agent/group-savings-pay/loan/:id/:savingsID",
    name: "group-savings-payment",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/groups/group-savings-pay.vue"),
  },
  {
    path: "/agent/pay/loan/group/:groupID/:id/:loadID",
    name: "make-payment-group",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/groups/pay-group.vue"),
  },
  {
    path: "/agent/customer/transactions/:id",
    name: "customer-transaction",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/agent/customer-transactions.vue"),
  },
  {
    path: "/agent/customer/savings/withdrawal/:id/:savingsID",
    name: "customer-withdrawal",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/customers/withdrawal.vue"),
  },
  {
    path: "/agent/group/savings/withdrawal/:id/:savingsID",
    name: "customer-group-withdrawal",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/groups/group-withdrawal.vue"),
  },
  {
    path: "/agent/reversal/:id",
    name: "agent-reversal",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/agent/reversal.vue"),
  },
  {
    path: "*",
    name: "404 Page",
    component: () => import("../views/pages/error-404.vue"),
  },
];


