import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueYoutube from "vue-youtube";
import vco from "v-click-outside";
import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";
import Toast from "vue-toastification";
import VueCookies from "vue-cookies";
import vueCountryRegionSelect from "vue-country-region-select";

import "@/assets/scss/app.scss";
import Axios from "axios";
Vue.prototype.$http = Axios;
const token = localStorage.getItem("token");
const tokenExpiration = localStorage.getItem("tokenExpiration");

// if (token) {
//   Vue.prototype.$http.defaults.headers.common["Authorization"] =`Bearer ${token}`;
// }

// Check if the token and expiration timestamp exist
if (token && tokenExpiration) {
  // Convert the tokenExpiration to a Date object
  const expirationDate = new Date(tokenExpiration * 1000); // Convert to milliseconds

  // Check if the token has expired
  if (new Date() < expirationDate) {
    // Token is valid, so attach it to the headers
    Vue.prototype.$http.defaults.headers.common["Authorization"] =
      `Bearer ${token}`;
    Vue.prototype.$http.defaults.headers.common["Content-Type"] =
      "application/json";
    Vue.prototype.$http.defaults.headers.common["Accept"] = "application/json";
    Vue.prototype.$http.defaults.headers.common["Access-Control-Allow-Origin"] =
      "https://www.agent.primecollect.org/";
    Vue.prototype.$http.defaults.headers.common[
      "Access-Control-Allow-Methods"
    ] = "GET, POST, PUT, DELETE";
  } else {
    // Token has expired, handle it here (e.g., log the user out)
    // You can call a logout function or redirect the user to the login page
    // Example:
    // logout();
    store.dispatch("logout");
  }
}

Vue.use(Toast);
import "vue-toastification/dist/index.css";
import "./registerServiceWorker";
Vue.config.productionTip = false;
Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places",
  },
  installComponents: true,
});
Vue.component("apexchart", VueApexCharts);
Vue.use(VueCookies);
Vue.use(vueCountryRegionSelect);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
